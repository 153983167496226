[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 38px;
    cursor: pointer;
    font-size: 16px;
    line-height: 21px;
    display: inline-block;
    color: #000;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 2px solid #4d9ac3;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #4d9ac3;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background: transparent;
}

.wrapper {
    padding: 0;
}

.form {
    max-width: 1226px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 18px;
    padding: 0 16px;
}

.form-wrapper {
    background-color: #f6f6f6;
}

.form-content-wrap {
    padding: 95px 174px 0;
}
.form-content {
    padding-bottom: 95px;
}

.form input {
    width: 100%;
    padding: 20px 16px;
    font-size: 18px;
    line-height: 22px;
    border-radius: 8px;
    border: 2px solid #8ac0e2;
    outline: none;
}

.form input:focus {
    background-color:rgba(43, 75, 242, 0.06);
    border-color:#6499ff;
}

.input-select input.pointer{
    position: relative;
    background: url("../images/select-arrow.svg") no-repeat;
    background-size: 15px;
    background-position: center right 20px;
}

.form .input-select input, .form .input-select .input:focus {
    background-color: #ebebeb;
    border-color: #ebebeb;
}
.select-dropdown {
    position: relative;
}
.select-dropdown ul {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    background-color: #fff;
    filter: drop-shadow(0px 4px 34px rgba(0, 0, 0, 0.25));
    border-radius: 8px;
}

.select-dropdown li {
    font-size: 18px;
    line-height: 22px;
    padding: 10px;
    color: #000;
}

.select-dropdown li:hover {
    background-color: #E7F0F8;
}

.error-border input, 
.form .input-select .error-border,
.form .error-border {
    border-color: #e52f2f;
}
  
.error-text {
    color: #e52f2f;
}

.radio-wrap {
    display: flex;
    height: 66px;
    align-items: center;
}

.radio-wrap > div {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.input-radio .image {
    margin-left: 14px;
}

input[name='laptop_price'] {
    background: url('../../assets/images/gel.svg') no-repeat right 16px center;
    background-size: 18px;
}

@media only screen and (max-width: 700px) {
    .form-content-wrap {
        padding: 0;
    }
    .form-content > .input {
        min-width: 100%;
        margin-top: 0 !important;
        margin-inline: 0!important;
    }
    .coworkers-form
    .form-content > .input {
        margin-bottom: 26px;
    }
    .form-content > .input-select {
        padding-bottom: 20px;
    }
    .form-content {
        padding-bottom: 0;
    }
    .form-content-wrap > .flx-mid > * {
        padding-top: 30px;
    }
    .form-content-wrap .divider {
        padding-bottom: 13px;
    }
    .divider-mobile {
        display: block;
        width: 100vw;
        margin-right: -16px;
        margin-left: -16px;
        position: relative;
        height: 34px;
        background-color: #f6f6f6;
        margin-bottom: 20px;
    }
    .divider-mobile:before,
    .divider-mobile:after {
        content: "";
        position: absolute;
        inset: 0;
        background: #fff;
        height: 12px;
    }
    .divider-mobile:before {
        border-radius: 0 0 12px 12px;
    }
    .divider-mobile:after {
        border-radius: 12px 12px 0 0;
        top: 22px;
    }
    .laptop-form
    .form-content > .input {
        margin-bottom: 26px !important;
    }
    .input-select:first-child {
        margin-bottom: 0!important;
    }
    .form-header {
        padding-top: 16px;
    }
    .form-wrapper >
    .header-wrap {
        padding: 16px 16px 0 16px
    }
    .mobile-form-page {
        font-size: 14px;
        line-height: 17px;
        color: #898989;
    }
}

