.upload-wrapper {
    display: block;
  }

.drag-area {
    width: 878px;
    height: 423px;
    background-color: #f6f6f6;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='%234386A9FF' stroke-width='2' stroke-dasharray='6%2c12' stroke-dashoffset='9' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 18px;
    overflow: hidden;
    position: relative;
}
.upload-wrapper .txt {
    color: rgb(67, 134, 169);
}

.upload-wrapper .error-text {
    color: #e52f2f;
}
.border-image-error {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='%23E52F2FFF' stroke-width='2' stroke-dasharray='6%2c12' stroke-dashoffset='9' stroke-linecap='square'/%3e%3c/svg%3e");
}

.drag-area img {
    max-width: 100%;
    object-fit: cover;
}

.background-error {
    background-color: rgba(255, 0, 0, 0.1);
}

.border-none {
    background-image: none;
}

.upload-button > .button {
    width: 233px;
    height: 60px;
}
.upload-button input,
.drag-area > label > input {
    display: none;
}
.drag-area > label,
.upload-button label {
    position: absolute;
    inset: 0;
}

.info-file-name {
    color: #202020
}

.info-file-size {
    color: #5f5f5f
}

.img-info {
    font-size: 18px;
    line-height: 22px;
}

@media only screen and (max-width: 700px) {
    .upload-wrapper, .drag-area {
        width: 100%;
    }
    .drag-area {
        height: 245px;
    }
    .upload-wrapper .divider {
        padding: 0;
    }
    .upload-button > .button {
        width: 187px;
        height: 46px;
    }
    .image-det-footer {
        font-size: 12px;
        line-height: 14px;
        color: #202020;
    }
    .laptop-form .form-content >
    .input-select[data-id="2"] {
        margin-bottom: 0!important;
    }
    .info-file-size {
        font-size: 12px;
        line-height: 14px;
        padding-top: 4px;
    }
}