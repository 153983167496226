@import "./assets/css/reset.css";

* {
  box-sizing: border-box;
  font-family: 'Helvetica Neue';
}

:root {
  --main-blue:#62a1eb;
  --main-black:#292929;
}

body {
  color: #000;
  font-family: 'Helvetica Neue';
}

.wrapper {
  display: block;
  margin: 0 auto;
  position: relative;
}

.wrp::before, .wrp::after {
  content: '';
  display: table;
  clear: both;
}

.flx {
  display: flex;
}

.flx-mid {
  justify-content: space-between;
}

.flx-grow {
  flex-grow: 1;
}
.flx-wrap {
  flex-wrap: wrap;
}
.flx-self-top {
  align-self: flex-start;
}
.flx-hc {
  justify-content: center;
}
.flx-hl {
  justify-content: flex-end;
}
.flx-hr {
  justify-content: flex-end;
}

.flx-vc {
  align-items: center;
}

.flx-vt {
  align-items:flex-start
}
.flx-vb {
  align-items:flex-end
}

.flx-r {
  display: flex;
  flex-direction: row;
}
.flx-c {
  display: flex;
  flex-direction: column;
}

.button-link {
  display: block;

}

.overlay {
  background-color: #4a4a4a;
  position: fixed;
  display: block;
  inset: 0;
  z-index: -1;
}

.popup {
  width: 847px;
  height: 537px;
  margin: 0 auto;
  background-color:#fff;
  z-index: 1;
}

.success {
  height: 100vh;
}

.pointer:hover {
  cursor: pointer;
}

.button {
  background-color: var(--main-blue);
  color: #fff;
  border: 0;
  padding: 18px 60px;
  border-radius: 8px;
  line-height: 24px;
  width: 100%;
  text-align: center;
}

.button:hover {
  background-color: #317ad0;
}

.button:active {
  background-color: #1a5dab;
}

.success-body {
  width: 297px;
}

.button:hover {
  cursor: pointer;
}

.popup .image-cont {
  margin-bottom: -15px;
}

.lnk {
  text-decoration: none;
}

.txt-link {
  color: #0089A7;
}

.header-wrap {
  display: block;
  padding: 53px 70px 27px
}

.button-back {
  height: 53px;
  width: 53px;
  background-color: #d9d9d9;
  border-radius: 100%;
}

.grid {
  display: grid;
}

.image-wrapper img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.image-wrapper {
  position: relative;
  overflow: hidden;
}

.image img {
  max-width: 100%;
  max-height: 100%;
}
.popup-body {
  align-items: center;
}

@media only screen and (max-width: 700px) {
  .wrapper {
    padding: 0 16px;
  }
  .header-wrap {
    padding: 34px 16px
  }
  .button-back {
    width: auto;
    height: 16px;
    background-color: transparent;
  }
  .popup {
    width: 100%;
    height: 100vh;
  }
  .success .popup .txt {
    max-width: 165px;
  }
  .success .popup .txt span {
    line-height: 40px;
  }
  .popup > .image-cont + span {
    text-align: center;
  }
}

