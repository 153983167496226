
.loading_wrap {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 20px;
    padding: 10px
  }
  .loading {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      animation: load-spin 0.8s infinite linear;
  }
  
  @keyframes load-spin {
    to {
      transform: rotate(1turn);
    }
  }