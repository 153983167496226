@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/subset-HelveticaNeue-Bold.eot');
    src: url('../fonts/subset-HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-HelveticaNeue-Bold.woff2') format('woff2'),
        url('../fonts/subset-HelveticaNeue-Bold.woff') format('woff'),
        url('../fonts/subset-HelveticaNeue-Bold.ttf') format('truetype'),
        url('../fonts/subset-HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/subset-HelveticaNeue-Medium.eot');
    src: url('../fonts/subset-HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-HelveticaNeue-Medium.woff2') format('woff2'),
        url('../fonts/subset-HelveticaNeue-Medium.woff') format('woff'),
        url('../fonts/subset-HelveticaNeue-Medium.ttf') format('truetype'),
        url('../fonts/subset-HelveticaNeue-Medium.svg#HelveticaNeue-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/subset-HelveticaNeue-Light.eot');
    src: url('../fonts/subset-HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-HelveticaNeue-Light.woff2') format('woff2'),
        url('../fonts/subset-HelveticaNeue-Light.woff') format('woff'),
        url('../fonts/subset-HelveticaNeue-Light.ttf') format('truetype'),
        url('../fonts/subset-HelveticaNeue-Light.svg#HelveticaNeue-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/subset-HelveticaNeue.eot');
    src: url('../fonts/subset-HelveticaNeue.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-HelveticaNeue.woff2') format('woff2'),
        url('../fonts/subset-HelveticaNeue.woff') format('woff'),
        url('../fonts/subset-HelveticaNeue.ttf') format('truetype'),
        url('../fonts/subset-HelveticaNeue.svg#HelveticaNeue') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/subset-HelveticaNeue-Thin.eot');
    src: url('../fonts/subset-HelveticaNeue-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-HelveticaNeue-Thin.woff2') format('woff2'),
        url('../fonts/subset-HelveticaNeue-Thin.woff') format('woff'),
        url('../fonts/subset-HelveticaNeue-Thin.ttf') format('truetype'),
        url('../fonts/subset-HelveticaNeue-Thin.svg#HelveticaNeue-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}