
.laptop-info-wrapper {
    max-width: 1178px;
    margin: 0 auto;
}

.info-group span {
    margin-bottom:20px;
    font-size: 22px;
    line-height: 27px;
}
  
.label-group {
    width: 300px
}

.label-group span {
    color: #2e2e2e;
    font-weight: 600;
}

.value-group span {
    color: #797979;
}

.laptop-info img {
    width: 577px;
    height: 342px;
}

.laptop-info-grid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 68px
}

@media only screen and (max-width: 700px) {
    .laptop-info-wrapper {
        padding: 0 16px
    }
    .laptop-info-grid {
        grid-template-columns: 1fr;
        grid-gap: 0
        
    }
    .laptop-info img {
        width: 100%;
        max-width: 358px;
        max-height: 191px;
    }
    .info-group span {
        margin-bottom: 2px;
        font-size: 14px;
        line-height: 25px;
    }
    .info-group {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .label-group {
        width: 100%;
    }
}
